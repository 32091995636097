import React, { Component } from 'react'
import { MapContainer, TileLayer, Popup, Marker } from 'react-leaflet';
import styled from "styled-components";
import VarqaschoolSVG  from '~/assets/svg/varqaschool';
const Div = styled.div`
    width: 100%;
    height: 25rem;
`;
const PopUp = styled(Popup)`
  a {
    display: flex;
    justify-content: center;
    color: #000000;
    font-size: 1.3rem;
    text-align: center;
    text-decoration: none;
    :hover {
      color: #BA884B;
    }
  }
`;
export default class MyMap extends Component {
  render() {

    if (typeof window !== 'undefined') {
      return (
        <>
        <Div>
        {(typeof window !== "undefined") ? (
          <MapContainer style={{height: "25rem", width:"100%"}} center={[19.030081466852835, 74.72609699910694]} zoom={13} scrollWheelZoom={false}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker position={[19.030081466852835, 74.72609699910694]}>
            <PopUp>
                <a href="https://g.page/r/CUjOQbIiy9gMEBA" target="_blank"><VarqaschoolSVG/><br/>Varqa School &amp; Jr. College Campus<br /> </a>
            </PopUp>
            </Marker>
          </MapContainer>
        ) : null}
      </Div>
      </>
    )
  }
  return null
}
}