import { graphql } from "gatsby";
import React, {useState} from "react";
import styled from "styled-components";
import { useStringsContext } from "../../contexts/strings-context";
import { SEO } from "../../components/SEO";
import { PageProps } from "../../types/global";
import { navigate } from 'gatsby-link';
import { SiMinutemailer, SiCoffeescript, SiFacebook, SiLinkedin, SiInstagram, SiWhatsapp, SiTwitter, SiGithub, SiGitlab, SiDiscord, SiTelegram, SiGmail } from "react-icons/si";
import { RiUser3Line } from "react-icons/ri";
import { BsChatText } from "react-icons/bs";
import ReCaptcha from "@pittica/gatsby-plugin-recaptcha"
import { MdQuestionAnswer} from "react-icons/md";
import MyMap from "~/components/myMap";
import { AiOutlinePhone } from "react-icons/ai";
import ContactDetail from "~/components/contactDetail";

export const query = graphql`
    query($language: String) {
        strings(language: { eq: $language }) {
            ...LayoutStringsFragment
            
          }
    }
`;
const GetInTouch = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 5rem;
`;

const MainText = styled.p`
    display: table-row;
`;
const MainTextCenter = styled(MainText)`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 1rem;
`;
const Center= styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Label = styled.label`
    display: table-cell;
    font-size: 1.2em;
    color: #bfc6bf;
    font-family: "Roboto";
    font-weight: 400;
    margin: 0;
    text-align: left;
    padding: 1rem;
`;
const Container = styled.div`
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    min-width: 100%;
    min-height: 100vh;
    margin: 0;
    height: 100%;
    overflow: hidden;
`;
const Form = styled.form`
    display: table;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 20px;
    border:1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    padding: 50px;
`;

const Input = styled.input`
    background: transparent;
    width: 100%;
    clear: both;
    color: white;
    display: table-cell;
    padding: 0.5rem;
    margin: 0.5rem 0;
    border-radius: 15px;
    background-color: #383838;
    font-family: 'Gloria Hallelujah';
`;
const TextArea = styled.textarea`
    background: transparent;
    width: 100%;
    clear: both;
    color: white;
    display: table-cell;
    padding: 0.5rem;
    margin: 0.5rem 0;
    border-radius: 15px;
    background-color: #383838;
    font-family: 'Gloria Hallelujah';
`;

const Button = styled.button`
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 20px;
    border:1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    padding: 0.2rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    color: #bfc6bf;
    font-family: "Roboto";
    font-weight: 400;
`;

const ContactUS = styled.p`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5em;
    color: #797979;
    font-family: "Roboto";
    font-weight: 400;
    padding: 0 0 1rem 0;
`;
const SiCoffeescriptWhite = styled(MdQuestionAnswer)`
    color: #fffddd;
    background: transparent;
    width: 3rem;
    height: 3rem;
    padding: 0 2rem;
`;

function encode(data) {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }
  
const ContactPage: React.FunctionComponent<PageProps> = (props)=> {
    const [state, setState] = React.useState({})

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  } 
    const strings = useStringsContext();
    const [submitted, setSubmitted] = useState(false)
    const submit = (token) => {}
    return (
    <>
    
    <SEO
                lang={props.pageContext.language}
                title="Contact Me"
                description="Varqa School &amp; Junior College is one of the most prestigious schools in Ahmednagar District, having been around for more than 40 years. The school is in an area that is rich in greenery and is surrounded by an extremely healthy and open environment. Our school is also partnered with Cambridge University to provide our students with more rich content, and the school is committed to maintaining highly-trained and innovative faculty."
                keywords={["varqa", "school", "varqa school", "varka", "vaka", "varkha", "varqa school", "college", "ahmenagar", "ahmednagar", "maharashtra", "best school", "schools near me", "schol", "schoole", "schools", "varqa", "best", "top schools", "cbse", "cbse school", "state board", "state", "english medium", "school medium", "english school", "english", "ramin", "ruhiya", "saba", "sir", "madam", "ahmednagr best school", "nagar best school", "nagar", "school in nagar", "nagar school"]}
                url={props.location.pathname}
            />
    <Container>
        <ContactUS>Get in touch<SiCoffeescriptWhite/></ContactUS>
        <Form 
            name="contact"
            method="post"
            action="/contact/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}>
            <input type="hidden" name="form-name" value="contact" />
            <p hidden>
                <label>Contact us <input name="bot-field" onChange={handleChange} />
                </label>
            </p>
            <MainText>
                <Label>Full Name: <RiUser3Line/><Input type="text" name="name" onChange={handleChange}/></Label>   
            </MainText>
            <MainText>
                <Label>Email ID: <SiMinutemailer/><Input type="email" name="email" onChange={handleChange}/></Label>
            </MainText>
            <MainText>
                <Label>Mobile No: <AiOutlinePhone/><Input type="tel" id="phone" name="phone" onChange={handleChange}/></Label>
            </MainText>
            <MainText>
                <Label>Text me: <BsChatText/><TextArea name="message" onChange={handleChange}></TextArea></Label>
            </MainText>
            <MainTextCenter>
                <Button data-action='submit' onClick={() => setSubmitted(true)} type="submit">Send</Button>
            </MainTextCenter>
            <Center>
                <ReCaptcha
                action="submit"
                siteKey="6LfufqceAAAAADVZVGy2gWR4S1DVMPB5TJbKuLkg"
                onVerify={(token) => submit(token)}
                submitted={submitted}
                size="invisible"
            />
            </Center>
        </Form>
    </Container>
    <ContactDetail/>
    <MyMap />
    </>
    )
}

export default ContactPage
