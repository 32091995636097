import React from "react";
import styled from "styled-components";
import { SiFacebook, SiLinkedin, SiInstagram, SiWhatsapp, SiTwitter, SiGithub, SiGitlab, SiDiscord, SiTelegram, SiGmail, SiYoutube } from "react-icons/si";
import { GrAnnounce } from "react-icons/gr";
import { FaRegAddressCard } from "react-icons/fa";
import { MdOutlineLocalLibrary } from "react-icons/md";

const Container = styled.div`
display: flex;
  flex-flow: row nowrap;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  height: 70vh;
  width: 100%;
  @media(max-width: 1300px) {
      flex-direction: column;
      margin-bottom: 3rem;
      height: auto;
    }
`;

const CardContact = styled.div`
  width: 20rem;
  height: 23rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  background: rgba(48, 47, 47, 0.315);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10.6px);
  -webkit-backdrop-filter: blur(10.6px);
  border-radius: 20px;
  text-decoration: none;
  :hover {
    box-shadow:-4px 8px 8px rgba(0,0,0,0.1), -8px 16px 16px rgba(0,0,0,0.1), -16px 32px 32px rgba(0,0,0,0.15), -32px 64px 64px rgba(0,0,0,0.25);
    cursor: pointer;
  }
`;
const CardContact1 = styled(CardContact)`
    width: 50rem;
    justify-content: space-evenly;
    background: rgba(137, 187, 233, 0.219);
    @media(max-width: 850px) {
      width: 90%;
    }
`;

const SiFacebookSize = styled(SiFacebook)`
    
    width: 4rem;
    height: 4rem;
    transition: 1s all;
    color: #0077ff;
`;
const FaRegAddressCardSize = styled(FaRegAddressCard)`
    width: 4rem;
    height: 4rem;
    transition: 1s all;
    margin: 2rem;
    color: #a7cc00;
`;
const MdOutlineLocalLibrarySize = styled(MdOutlineLocalLibrary)`
    width: 4rem;
    height: 4rem;
    transition: 1s all;
    align-content: center;
    margin: 2rem 0;
    color: #00cca0;
`;

const GrAnnounceSize = styled(GrAnnounce)`
    position: absolute;
    top: -7rem;
    width: 8rem;
    height: 8rem;
    transition: 1s all;
    color: #f6f9fc;
    border-radius: 20rem;
    padding: 1rem;
    border: 1px solid red;
    color: #0077ff;
`;

const SiInstagraSize = styled(SiInstagram)`
    
    width: 4rem;
    height: 4rem;
    transition: 1s all;
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
        border-radius: 20px;
`;
const SiWhatsappSize = styled(SiWhatsapp)`
    
    width: 4rem;
    height: 4rem;
    transition: 1s all;
    color: #0afd17;
`;
const SiTwitterSize = styled(SiTwitter)`
    
    width: 4rem;
    height: 4rem;
    transition: 1s all;
    color: #00c3ff;
`;
const SiGithubSize = styled(SiGithub)`
    
    width: 4rem;
    height: 4rem;
    transition: 1s all;
    color: #5b00c4;
`;
const SiGitlabSize = styled(SiGitlab)`
    
    width: 4rem;
    height: 4rem;
    transition: 1s all;
    color: #ff5e00;
`;
const SiDiscordSize = styled(SiDiscord)`
    
    width: 4rem;
    height: 4rem;
    transition: 1s all;
    color: #4e3fdb;
`;
const SiTelegramSize = styled(SiTelegram)`
    
    width: 4rem;
    height: 4rem;
    transition: 1s all;
    color: #0099ffc8;
`;
const SiLinkedinSize = styled(SiLinkedin)`

    width: 4rem;
    height: 4rem;
    transition: 1s all;
    color: #044fb1;
`;

const SiGmailSize = styled(SiGmail)`
    
    width: 4rem;
    height: 4rem;
    transition: 1s all;
    color: red;
`;
const SiYoutubeSize = styled(SiYoutube)`
    width: 4rem;
    height: 4rem;
    transition: 1s all;
    color: red;
`;

const Message = styled.p`
margin: 1rem;
display: flex;
align-content: center;
justify-content: center;
flex-flow: row wrap;
    a {
        margin: 1rem;
        text-decoration: none;
        color: white;
    }
`;
const Heading = styled.h1`
    text-align: center;
    color: #fff;
    font-family: "Roboto";
    font-size: 1.5rem;
`;

const Address = styled.p`
    margin: 0 2rem;
    font-family: "Roboto";
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: #b4b4b4;
    text-decoration: none;
`;

const ContactDetail: React.FC = () => {
    return (
        <>
        <Container>
            <CardContact as="a" href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x3bdcb17a40d6848b:0xcd8cb22b241ce48?source=g.page.m.we._" target="_blank" style={{background: "rgba(137, 187, 233, 0.219)"}}>
            
                <FaRegAddressCardSize/>
            <Heading style={{color:"#fff", textAlign:"start", margin:"0 2rem 2rem"}}>Address:</Heading>
            <Address>Varqa School &amp; J.r College,<br/> Arangaon,<br/> Ahmednagar,<br/> Maharashtra 414005,<br/> India</Address>
                 </CardContact>
            <CardContact><Heading>SOCAIL CHANNEL</Heading><Message>
                <a href="https://www.facebook.com/varqaschoolandjrcollege" target="_blank"><SiFacebookSize/></a>
                <a href="https://www.instagram.com/varqaschool" target="_blank"><SiInstagraSize/></a>
                <a href="https://www.youtube.com/channel/UCP8kqceqqNt5DxjOeo-q7eA" target="_blank"><SiYoutubeSize/></a>
                <a href="https://wa.me/+919822408237" target="_blank"><SiWhatsappSize/></a>
                <a href="https://www.linkedin.com/company/varqa-school/" target="_blank"><SiLinkedinSize/></a>
                <a href="https://discord.gg/SwjxHw5k" target="_blank"><SiDiscordSize/></a>
                <a href="https://t.me/+058fNwP0pFxkYzU0" target="_blank"><SiTelegramSize/></a>
                <a href="mailto: varqaschool@gmail.com" target="_blank"><SiGmailSize/></a>
                
            </Message></CardContact>
            <CardContact style={{background: "rgba(137, 187, 233, 0.219)", alignItems:"center"}}>
                <MdOutlineLocalLibrarySize/>
            <Heading style={{textAlign:"start", margin:"0 2rem 2rem"}}>Contact:</Heading>
            <Address style={{textAlign:"center"}}> <a style={{textDecoration:"none", color:"#bdba00"}} href="tel:+919822408237">+91-9822408237</a></Address>
            <Heading style={{textAlign:"start", margin:"2rem"}}>Email ID:</Heading>
            <Address style={{textAlign:"center"}}> <a style={{textDecoration:"none", color:"#5ca8ff"}} href="mailto: varqaschool@gmail.com">varqaschool@gmail.com</a></Address>
        </CardContact>
        </Container>
        </>
    );
};
export default ContactDetail;